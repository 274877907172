import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const dashboardStyles = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  marginTop: '20px',
};

const headerStyles = {
  backgroundColor: '#333',
  color: '#fff',
  textAlign: 'center',
  padding: '20px',
};

const tableStyles = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
  overflowX: 'auto',
};

const thStyles = {
  backgroundColor: '#333',
  color: '#fff',
  padding: '10px',
  textAlign: 'left',
};

const tdStyles = {
  border: '1px solid #ccc',
  padding: '10px',
};

const buttonStyles = {
  backgroundColor: 'blue',
  color: '#fff',
  border: 'none',
  padding: '5px 10px',
  cursor: 'pointer',
  whiteSpace: 'nowrap'
};

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    border: 'none',
  },
};

function AdminDashboard() {
  const history = useHistory();
  const [data, setData] = useState({ students: [], teachers: [] });
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [logoutClicked, setLogoutClicked] = useState(false); // State to handle logout
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isAddsessionsModalOpen, setIsAddsssionsModalOpen] = useState(false);
  const [quantity, setQuantity] = useState('');
  const [operation, setOperation] = useState('');
  
  useEffect(() => {
    const studentId = localStorage.getItem('studentId');
    const teacherId = localStorage.getItem('teacherId');
    const adminId = localStorage.getItem('Admin');
   
    if (studentId) {
      history.push('/studentdashboard');
    } else if (teacherId) {
      history.push('/teacherdashboard');
    } else if (adminId) {
      history.push('/admin');
    } else {
      history.push('/');
    }

    
    
    
    
    // Fetch students data
    axios
      .post('https://syllatutors.com/syllatutor/getstudentandteachers.php', {
        action: 'getStudents',
      })
      .then((response) => {
        setData((prevData) => ({ ...prevData, students: response.data.students }));
      })
      .catch((error) => {
        console.error('Error fetching students:', error);
      });

    // Fetch teachers data
    axios
      .post('https://syllatutors.com/syllatutor/getstudentandteachers.php', {
        action: 'getTeachersonly',
      })
      .then((response) => {
        setData((prevData) => ({ ...prevData, teachers: response.data.teachers }));
      })
      .catch((error) => {
        console.error('Error fetching teachers:', error);
      });
  }, []);

  const assignTeacherToStudent = () => {
    if (selectedTeacher) {
      const assignmentData = {
        Student_Id: selectedStudent.Id,
        Teacher_Id: selectedTeacher,
      };

      axios
        .post('https://syllatutors.com/syllatutor/studentandteacherconnection.php', assignmentData)
        .then((response) => {
          console.log('Teacher assigned successfully:', response.data);
          if(response.data.message=="Teacher assigned successfully"){
          
            closeModal();
            Swal.fire({
              icon: 'success',
              title: 'Student assigend Successful',
              text: 'Student assigned to teacher!',
            });}
            else{
              Swal.fire({
                icon: 'error',
                title: 'already  assgined a  teacher',
                text: 'Teacher have already assigned for this  student!',
              });
  
            }
     
        })
        .catch((error) => {
          console.error('Error assigning teacher:', error);
        });
    } else {
      console.error('No teacher selected for assignment');
    }
  };

  const assignTeacher = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };
  const deletestudent = (id) => {
    if (id) {
      const assignmentData = {
        id: id,
        action: 'studentdelete',
      };
      console.log(assignmentData);
    axios
    .post('https://syllatutors.com/syllatutor/delete.php', assignmentData )
    .then((response) => {
      console.log('Teacher assigned successfully:', response.data);
       if(response.data=="Success"){
      
       
        Swal.fire({
          icon: 'success',
          title: 'Student deleted Successful',
          text: 'Student deleted!',
        });}
        else{
          Swal.fire({
            icon: 'error',
            title: "Contact Admin",
            text: "Contact Admin",
          });

        }
 
    })
    .catch((error) => {
      console.error('Error deleting student:', error);
    });
  }}
  ;
  const deleteteacher = (id) => {
    if (id) {
      const assignmentData = {
        id: id,
        action: 'teacherdelete',
      };
      console.log(assignmentData);
    axios
    .post('https://syllatutors.com/syllatutor/delete.php', assignmentData )
    .then((response) => {
      console.log('Teacher assigned successfully:', response.data);
       if(response.data=="Success"){
      
       
        Swal.fire({
          icon: 'success',
          title: 'Teacher deleted Successful',
          text: 'Teacher deleted!',
        });}
        else{
          Swal.fire({
            icon: 'error',
            title: "Contact Admin",
            text: "Contact Admin",
          });

        }
 
    })
    .catch((error) => {
      console.error('Error deleting student:', error);
    });
  }}
  ;

  const closeModal = () => {
    setSelectedStudent(null);
    setSelectedTeacher('');
    setIsModalOpen(false);
  };

  const handleTeacherChange = (event) => {
    setSelectedTeacher(event.target.value);
  };

  const handleLogout = () => {
    // Add your logout logic here, e.g., clearing authentication tokens, etc.
    // After logout logic, you can redirect to the login page or perform other actions.
    // For now, we'll just set the logoutClicked state to true.

    setLogoutClicked(true);
  };
  const handlePasswordChange = () => {
    if (currentPassword && newPassword === confirmPassword) {
      const teacherId = localStorage.getItem('Admin');
      const passwordData = {
        id: teacherId,
        currentPassword,
        newPassword,
        role: 'Admin'
      };
      
      console.log(passwordData);
      axios
        .post('https://syllatutors.com/syllatutor/passwordchange.php', passwordData)
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            setIsPasswordChangeModalOpen(false);
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            Swal.fire({
              icon: 'success',
              title: 'Password Changed Successfully',
              text: 'Your password has been updated!',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Password Change Failed',
              text: 'Please check your current password and try again.',
            });
          }
        })
        .catch((error) => {
          console.error('Error changing password:', error);
        });
    } else {
      console.error('Invalid password change request');
    }
  };

  //add sessions
  const openmodeladdsessions = (studentId) => {
 
   localStorage.setItem("ssidforsession",studentId);
    setIsAddsssionsModalOpen(true);

  }
  const handleAddSessionsChange = () => {
   
    if (quantity && localStorage.getItem("ssidforsession")) {
    
      const sessionadd = {
        id: localStorage.getItem("ssidforsession"),
        quantity,
        operation
       
      };
      
      console.log(sessionadd);
      axios
        .post('https://syllatutors.com/syllatutor/addsessionbyadmin.php', sessionadd)
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            setIsAddsssionsModalOpen(false);
            setQuantity('');
            
            Swal.fire({
              icon: 'success',
              title: 'Added Successfully',
              text: 'Added Successfully to Id nnumber: '+ localStorage.getItem("ssidforsession"),
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Not added try again.',
            });
          }
        })
        .catch((error) => {
          console.error('Error adding:', error);
        });
    } else {
      console.error('Invalid  change request');
    }
  };
  // If logoutClicked is true, you can redirect to the login page or perform other actions.
  
  if (logoutClicked) {
    localStorage.removeItem('Admin');
    localStorage.removeItem('quantity');
    return <Redirect to="/" />;
  }

  return (
    <div style={dashboardStyles}>
      <div style={headerStyles}>
        <h1>Welcome to the Admin Dashboard</h1>
        <Button variant="contained" color="secondary"  onClick={handleLogout}>
            Logout
          </Button>
          <Button variant="contained" color="primary" onClick={() => setIsPasswordChangeModalOpen(true)}>
          Change Password
        </Button >
      </div>
      <h2>Categories</h2>

      <h3>Students</h3>
      <div style={tableStyles}>
        <table>
          <thead>
            <tr>
              <th style={thStyles}>ID</th>
              <th style={thStyles}>Firstname</th>
              <th style={thStyles}>Lastname</th>
              <th style={thStyles}>Email</th>
              <th style={thStyles}>Total Sessions</th>
              <th style={thStyles}>Remaining Sessions</th>
              <th style={thStyles}>Actions</th>
              <th style={thStyles}>Show teachers</th>
          
              
            </tr>
          </thead>
          <tbody>
            {data.students.map((student) => (
              <tr key={student.Id}>
                <td style={tdStyles}>{student.Id}</td>
                <td style={tdStyles}>{student.Student_First_Name}</td>
                <td style={tdStyles}>{student.Student_Last_Name}</td>
                <td style={tdStyles}>{student.Email}</td>
                <td style={tdStyles}>{student.Sessions}</td>
                <td style={tdStyles}>{student.RemSessions}</td>
                <td style={tdStyles}>
                  <button style={buttonStyles} onClick={() => assignTeacher(student)}>
                    Assign Teacher
                  </button>
                  <br></br><br></br>
                  <button style={buttonStyles} onClick={() => deletestudent(student.Id)} >
                    Delete Student
                  </button>
                </td>
                <td style={tdStyles}>
                <Link style={buttonStyles}  to="/teacherlist"   onClick={() => localStorage.setItem("studentIId",student.Id)} >
                Track Teachers
                  </Link>
                  <br></br>

                  <br></br>
                  <button style={buttonStyles} onClick={() => openmodeladdsessions(student.Id)} >
                    Add Sessions
                  </button>
                 
                  
                 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h3>Teacher</h3>
      <div style={tableStyles}>
        <table>
          <thead>
            <tr>
              <th style={thStyles}>ID</th>
              <th style={thStyles}>Firstname</th>
              <th style={thStyles}>Lastname</th>
              <th style={thStyles}>Email</th>
              <th style={thStyles}>Actions</th>
             
            </tr>
          </thead>
          <tbody>
            {data.teachers.map((student) => (
              <tr key={student.Id}>
                <td style={tdStyles}>{student.Id}</td>
                <td style={tdStyles}>{student.Teacher_First_Name}</td>
                <td style={tdStyles}>{student.Teacher_Last_Name}</td>
                <td style={tdStyles}>{student.Email}</td>
                <td style={tdStyles}>
                 
                  <button style={buttonStyles} onClick={() => deleteteacher(student.Id)} >
                    Delete Teacher
                  </button>
                </td>
             
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={customModalStyles}>
        {selectedStudent && (
          <>
            <h2>Assign Teacher to Student</h2>
            <p>ID: {selectedStudent.Id}</p>
            <p>Firstname: {selectedStudent.Student_First_Name}</p>
            <p>Lastname: {selectedStudent.Student_Last_Name}</p>
            <div style={{ marginBottom: '10px' }}>Select a Teacher:</div>
            <select value={selectedTeacher} onChange={handleTeacherChange}>
              <option value="">Select a Teacher</option>
              {data.teachers.map((teacher) => (
                <option key={teacher.Id} value={teacher.Id}>
                  {teacher.Teacher_First_Name} {teacher.Teacher_Last_Name}
                </option>
              ))}
            </select>
            <button onClick={assignTeacherToStudent}>Assign Teacher</button>
            <button onClick={closeModal}>Close</button>
          </>
        )}
      </Modal>
        {/* Password Change Modal */}
        <Modal
        isOpen={isPasswordChangeModalOpen}
        onRequestClose={() => setIsPasswordChangeModalOpen(false)}
        style={customModalStyles}
      >
        <h2>Change Password</h2>
        <div>
          <label>Current Password:</label>
          <input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
        </div>
        <div>
          <label>New Password:</label>
          <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
        <div>
        <Button variant="contained" color="primary" onClick={handlePasswordChange}>
          Save
        </Button>
        <Button variant="contained"  style={{textAlign:'right'}} color="secondary" onClick={() => setIsPasswordChangeModalOpen(false)}>
          Cancel
        </Button>
        </div>
      </Modal>
      {/* Add Sessions  Modal */}
      <Modal
  isOpen={isAddsessionsModalOpen}
  onRequestClose={() => setIsAddsssionsModalOpen(false)}
  style={customModalStyles}
>
  <h2>Add Sessions</h2>
  <div>
    <label>Amount to add/deduct Sessions:</label>
    <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
  </div>

  <div>
    <div>
      <label>
        <input
          type="radio"
          value="add"
          checked={operation === "add"}
          onChange={() => setOperation("add")}
        />
        Add Sessions
      </label>
    </div>
    <div>
      <label>
        <input
          type="radio"
          value="deduct"
          checked={operation === "deduct"}
          onChange={() => setOperation("deduct")}
        />
        Deduct Sessions
      </label>
    </div>
  </div>

  <div>
    <Button variant="contained" color="primary" onClick={handleAddSessionsChange}>
      {operation === "add" ? "Add Session" : "Deduct Session"}
    </Button>
    <Button
      variant="contained"
      style={{ textAlign: "right" }}
      color="secondary"
      onClick={() => setIsAddsssionsModalOpen(false)}
    >
      Cancel
    </Button>
  </div>
</Modal>

    </div>
  );
}

export default AdminDashboard;
