import React, { Component } from "react";
import { base_url } from "../config/constant"

import bannerImage from '../assets/img/07/image-asset-1.jpeg'; // Make sure the path to your image is correct

const description = [
  {
    title: "Bespoke Online Tutoring",
    description:
      "We have been tutoring for a while now, and with that experience comes an idea of exactly what each student requires to excel and achieve their goals. Our one-to-one tutoring is accompanied by a regular tutor, allowing for a rapport to be established",
  },
];

class Banner extends Component {
  render() {
    const bannerStyle = {
      backgroundImage: `url(${bannerImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };

    return (
      <React.Fragment>
        <section className="page-section banner-section" id="banner" style={bannerStyle}>
          <div className="container">
            <div className="row justify-content-center">
              {description.map((description, index) => {
                return (
                  <div className="col-md-6" key={index}>
                    <div className="service-item text-center">
                      <h2 className="text-uppercase " style={{color:"#deb15d", fontWeight:600}}>1:1 Bespoke Online Tutoring</h2>
                      <p  style={{color:"#deb15d"}}>We have been tutoring for a while now, and with that experience comes an idea of exactly what each student requires to excel and achieve their goals. Our one-to-one tutoring is accompanied by a regular tutor, allowing for a rapport to be established</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Banner;
