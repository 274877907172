import React, { Component } from 'react'
import Header from '../Common/Header'
import image from "../assets/teach.jpg"


// Re-usable componets
import Services from "../Common/Services"
import Footer from '../Common/Footer'
import Banner from '../Common/Banner'
import Testimonials from '../Common/Testimonials'
import Courseboard from '../Common/Courseboard'
import ListItem from '../Common/LastItem'



class Home extends Component {
 


    render() {
    
        return (
        
               <>
                    <Header />
                    <Services /> 
                    <Banner/>
                    <Testimonials/>
                    <Courseboard/>
                    <ListItem/>            
                    <Footer/>
                </>
            
        )
    }
}

export default Home
