import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';

function BuySessionDialog({ isOpen, onClose, onSubmit }) {
  const [quantity, setQuantity] = useState(1);
  const [stripePromise, setStripePromise] = useState(null);
  const [priceOfProduct, setPriceOfProduct] = useState('');

  function callswiral() {
    Swal.fire({
      icon: 'error',
      title: 'Payment problem',
      text: 'Network problem or insufficient balance',
    });
  }

  useEffect(() => {
    const fetchPaymentSettings = async () => {
      try {
        const response = await fetch('https://syllatutors.com/syllatutor/fetch_payment_settings.php');
        const data = await response.json();
        setStripePromise(loadStripe(data.stripeKey));
        setPriceOfProduct(data.priceId);
      } catch (error) {
        console.error('Error fetching payment settings:', error);
        callswiral();
      }
    };

    fetchPaymentSettings();
  }, []);

  const handleSubmit = async () => {
    onSubmit(quantity);
    localStorage.setItem('quantity', quantity);

    if (!stripePromise || !priceOfProduct) {
      console.error('Stripe or price information not loaded yet.');
      return;
    }

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: [
        {
          price: priceOfProduct,
          quantity: parseInt(quantity, 10),
        },
      ],
      successUrl: 'https://syllatutors.com/success',
      cancelUrl: 'https://syllatutors.com/cancel',
    });

    if (error) {
      console.error('Error redirecting to Stripe Checkout:', error);
    }
  };

  return (
    <div style={{ display: isOpen ? 'block' : 'none', position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '9999' }}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
        <h2>Enter Quantity</h2>
        <input
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
        <button onClick={handleSubmit}>Buy</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}

export default BuySessionDialog;
