import React from 'react';
import { Link } from 'react-router-dom';

const facebookLink = "https://www.facebook.com/profile.php?id=100091937411234%20";
const instagramLink = "https://instagram.com/syllatutors?igshid=MjEwN2IyYWYwYw==";

function Footer(props) {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
  };

  const syllatutorsStyle = {
    textAlign: 'center',
    margin: '10px 0',
  };

  const listStyle = {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  };

  const textBlack = {
    color: 'black',
    fontSize: '20px',
  };

  const socialMediaStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };

  const socialMediaIconStyle = {
    fontSize: '40px',
    color: '#000000',
    margin: '0 10px',
  };

  return (
    <section style={{ background: "#ebeadf" }}>
      <div className="container">
        <div className='row'>
          <div className="col-md-12 col-lg-9" style={syllatutorsStyle}>
            <h2 style={{ marginTop: '20px', textAlign: 'left'}}><a href="/" style={{color: 'black'}}>SYLLATUTORS</a></h2>
            <p style={{ marginTop: '100px', textAlign: 'left' }}>Personalized tuition services</p>
          </div>
          <div className="col-md-12 col-lg-3" style={containerStyle}>
            <ul style={listStyle}>
            <li>
                <Link to="/termsservice" style={textBlack}>
                  <span>Terms of Service</span>
                </Link>
              </li>
              <li>
                <Link to="/privacypolicy" style={textBlack}>
                  <span>Privacy Policy</span>
                </Link>
              </li>
              <li>
                <Link to="/contactus" style={textBlack}>
                  <span>Contact</span>
                </Link>
              </li>
              <li>
                <Link to="/copyrigtstatement" style={textBlack}>
                  <span>Copyright Statement</span>
                </Link>
              </li>
              <li>
                <Link to="/safeguarding" style={textBlack}>
                  <span>Safeguarding Policy</span>
                </Link>
              </li>
            </ul>
            <div style={socialMediaStyle}>
              <a href={facebookLink}>
                <i className="fab fa-facebook" style={socialMediaIconStyle}></i>
              </a>
              <a href={instagramLink}>
                <i className="fab fa-instagram" style={socialMediaIconStyle}></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
