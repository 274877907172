import React, { Component } from "react";
import Footer from '../../Common/Footer';

class CopyrightStatement extends Component {
  render() {
    const containerStyle = {
    backgroundColor: "#ebeadf",
    padding: "20px",
    paddingTop: "80px",
    
   
    };

    const contentStyle = {
      textAlign: "left",
       backgroundColor: "#ebeadf",
       margin: "10%",
  
   
    };

    return (
      <React.Fragment>
        <section style={containerStyle}>
          <div style={contentStyle}>
            <h2 style={{ fontWeight: 'bold' }}>Copyright Statement</h2>

            <strong>Last Updated:</strong> [20/06/2023]

            <p>
              The content, design, and materials on the SYLLATUTORS website (“Website”) are protected by copyright laws and intellectual property rights. All rights are reserved. The following copyright statement outlines the terms and conditions governing the use of the content on our Website.
            </p>

            <strong>Ownership and Restrictions</strong>

            <p>
              (a) Ownership: The content on the Website, including but not limited to text, graphics, images, logos, videos, and audio files, is the property of SyllaTutors or its content suppliers and is protected by copyright laws.
            </p>

            <p>
              (b) Restrictions: Except as expressly permitted in this copyright statement or with our prior written consent, you may not reproduce, modify, distribute, transmit, display, perform, publish, license, create derivative works from, transfer, or sell any information or content obtained from the Website.
            </p>

            <strong>Permissible Use</strong>

            <p>
              (a) Personal Use: You may access and use the content on the Website for personal and non-commercial purposes, including browsing, learning about our tutoring services, and interacting with the provided information.
            </p>

            <p>
              (b) Tutoring Services: Tutors affiliated with SyllaTutors may use the content on the Website in connection with providing tutoring services to students or clients. Such use is limited to the extent necessary to support the tutoring activities and is subject to compliance with applicable laws and regulations.
            </p>

            <strong>Intellectual Property Rights</strong>

            <p>
              (a) Trademarks: “SyllaTutors” and our logo are trademarks and service marks owned by SyllaTutors. Any use of these marks without our prior written consent is strictly prohibited.
            </p>

            <p>
              (b) User Content: If you submit any content, feedback, or suggestions to SyllaTutors through the Website, you grant us a non-exclusive, worldwide, royalty-free, perpetual, and irrevocable right to use, reproduce, modify, adapt, publish, distribute, and display such content for any purpose.
            </p>

            <strong>Reporting Copyright Infringement</strong>

            <p>
              We respect the intellectual property rights of others. If you believe that any content on our Website infringes your copyright, please contact us immediately and provide the following information:
            </p>

            <ul>
              <li>Identification of the copyrighted work claimed to have been infringed;</li>
              <li>Description of the infringing material and its location on the Website;</li>
              <li>Your contact information, including name, address, phone number, and email address;</li>
              <li>A statement declaring that you have a good faith belief that the use of the content is not authorized by the copyright owner, its agent, or the law; and</li>
              <li>A statement made under penalty of perjury that the information provided is accurate and that you are the copyright owner or authorized to act on behalf of the copyright owner.</li>
            </ul>

            <strong>Changes to the Copyright Statement</strong>

            <p>
              We reserve the right to modify or update this copyright statement at any time without prior notice. The revised statement will be posted on our Website, and the “Last Updated” date will indicate the effective date of the revised copyright statement.
            </p>

            <strong>Contact Us</strong>

            <p>
              If you have any questions, concerns, or requests regarding this copyright statement or our use of copyrighted material, please contact us at [email address].
            </p>

            <strong>By accessing and using the SyllaTutors website, you agree to comply with this copyright statement and all applicable laws and regulations regarding the use of copyrighted material.</strong>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default CopyrightStatement;
