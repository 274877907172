// SuccessPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';

function CancelPage() {
  // Use the useLocation hook to access query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  

  return (
    <div>
      <h1>Payment Failed</h1>
    
      {/* You can add more content or actions here */}
    </div>
  );
}

export default CancelPage;
