import React from 'react';
import {Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import {TextField} from 'formik-material-ui'
import {Field,Form,Formik} from 'formik'
import {LoginSchema} from '../../../../utils/ValidationSchema'
import axios from 'axios';
import Swal from 'sweetalert2';

import { useHistory } from 'react-router-dom';
// import { AppBar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  login: {
    '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '80%',
    },
    },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    // marginLeft:'10%'
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();
  const history = useHistory();

  return (
      <React.Fragment>
            <Formik initialValues={{
                email:'',
                password:''
            }}
            onSubmit={async (data, { setSubmitting }) => {
                try {
                  // Make an Axios POST request to your API
                  const response = await axios.post('https://syllatutors.com/syllatutor/login_teacher.php', data);
                  console.log(response.data.Data.Id)
                 
    
                  // Check if the response contains an 'email' field and 'password' field
                  if (response.data.message== 'Success') {
                    // Display a success message using SweetAlert
                    localStorage.setItem("teacherId",response.data.Data.Id);
                    history.push('/teacherdashboard');
                  } else {
                    // Handle the case where the response does not have the expected fields
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: 'Invalid response format',
                    });
                  }
                } catch (error) {
                  // Handle any error that occurs during the Axios request
                  console.error('Error:', error);
    
                  // Display an error message using SweetAlert
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred while making the request',
                  });
                } finally {
                  setSubmitting(false); // Make sure to set isSubmitting to false
                }
              }}
            validationSchema={LoginSchema}
            >
                {({isSubmitting, submitForm})=>(
                    <div className={classes.root}>
                    <h1 className="login_header">Login | Registration - syllatutors (Teacher) </h1>
                    <Container maxWidth="m">
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Paper className={classes.paper}>
                                    <h1 className="login_h1" >Login to your account</h1>
                                    <p className="login_p">If you are registered, enter your login and password and click «Login»</p>
                                    <Form className={classes.login}>
    
                                        <Field
                                            name="email"
                                            type="email"
                                            label="Email or username"
                                            variant="outlined" 
                                            id="outlined-error" 
                                            component={TextField}
                                        />
    
                                        <Field
                                            name="password"
                                            type="password"
                                            label="Enter Password"
                                            variant="outlined" 
                                            id="outlined-error" 
                                            component={TextField}
                                        />
                                        <div>
                                            <Button 
                                                startIcon={isSubmitting ? <CircularProgress size="1rem" /> :null}
                                                type="submit" 
                                                variant="contained" 
                                                color="primary" 
                                                className=" m-2" 
                                                disabled={isSubmitting}  
                                                onClick={submitForm}
                                            >
                                            Login
                                            </Button>
                                        </div>
                                    </Form>
                                </Paper>
                            </Grid>
                            <Grid item md={6}>
                            <Paper className={classes.paper}> <p className="login_p">Not registered?</p> 
                                <div>
                                    <Link to="/teacherregister">
                                    <Button 
                                        // startIcon={isSubmitting ? <CircularProgress size="1rem" /> :null}
                                        type="submit" 
                                        variant="contained" 
                                        color="primary" 
                                        className=" m-2" 
                                        disabled={isSubmitting}
                                    >
                                    Register
                                    </Button>
                                    </Link>
                                </div>
                            </Paper>
                            </Grid>
                        </Grid>
                </Container>
                </div>
                )}
            </Formik>
    </React.Fragment>
  );
}
