import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { LoginSchema } from '../../../../utils/ValidationSchema';
import axios from 'axios'; // Import Axios
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '20px',
  },
  login: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '80%',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={async (data, { setSubmitting }) => {
            try {
              // Make an Axios POST request to your API
              const response = await axios.post('https://syllatutors.com/syllatutor/login_admin.php', data);
              console.log(response.data)

              // Check if the response contains an 'email' field and 'password' field
              if (response.data.message== 'Success') {
                // Display a success message using SweetAlert
                localStorage.setItem("Admin",response.data.Data.Id);
                history.push('/admin');
              } else {
                // Handle the case where the response does not have the expected fields
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Invalid response format',
                });
              }
            } catch (error) {
              // Handle any error that occurs during the Axios request
              console.error('Error:', error);

              // Display an error message using SweetAlert
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while making the request',
              });
            } finally {
              setSubmitting(false); // Make sure to set isSubmitting to false
            }
          }}
          validationSchema={LoginSchema}
        >
          {({ isSubmitting, submitForm }) => (
            <Container maxWidth="md">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <center>
                      <h1 className="login_header">Login | Registration - syllatutors</h1>
                      <p className="login_p">
                        If you are registered, enter your login and password and click "Login"
                      </p>
                      <Form className={classes.login}>
                        <Field
                          name="email"
                          type="email"
                          label="Email or username"
                          variant="outlined"
                          id="outlined-email"
                          component={TextField}
                        />
                        <Field
                          name="password"
                          type="password"
                          label="Enter Password"
                          variant="outlined"
                          id="outlined-password"
                          component={TextField}
                        />
                        <div>
                          <Button
                            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="m-2"
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            Login
                          </Button>
                        </div>
                      </Form>
                    </center>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
