import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const dashboardStyles = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  marginTop: '20px',
};

const headerStyles = {
  backgroundColor: '#333',
  color: '#fff',
  textAlign: 'center',
  padding: '20px',
};

const tableStyles = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
  overflowX: 'auto',
  
};

const thStyles = {
  backgroundColor: '#333',
  color: '#fff',
  padding: '10px',
  textAlign: 'left',
};

const tdStyles = {
  border: '1px solid #ccc',
  padding: '10px',
};

const buttonStyles = {
  backgroundColor: 'blue',
  color: '#fff',
  border: 'none',
  padding: '5px 10px',
  cursor: 'pointer',
};

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    border: 'none',
  },
};

function TrackTeacher() {
  const history = useHistory();
  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [data, setData] = useState({ students: [], teachers: [] });
  const [teacherData, setteacherData] = useState({ teacherData: [], studentData: [] });
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [logoutClicked, setLogoutClicked] = useState(false); // State to handle logout
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  useEffect(() => {
    const studentId = localStorage.getItem('studentId');
    const teacherId = localStorage.getItem('teacherId');
    const adminId = localStorage.getItem('Admin');
  
    if (studentId) {
      history.push('/studentdashboard');
    } else if (teacherId) {
      history.push('/teacherdashboard');
    } else if (adminId) {
      history.push('/trackteacher');
    } else {
      history.push('/');
    }
    // Fetch students data
    axios
    .post('https://syllatutors.com/syllatutor/getteacherclasses.php', {
      studentId:localStorage.getItem("studentIId"),
      teacherId:localStorage.getItem("teacherIId"),
      startDate:"2023-02-01",
      endDate:"2023-02-01"
      })
      .then((response) => {
       setData((prevData) => ({ ...prevData, students: response.data.sessions }));
        console.log(response);
      })
      .catch((error) => {
        console.error('Error fetching students:', error);
      });

   
  }, []);

  const handlestartdate = (event) => {
    setstartDate(event.target.value);
  };
  const handleenddate = (event) => {
    setendDate(event.target.value);
  };


  const submitedate = () => {
    console.log(startDate+" "+endDate);
     // Fetch students data
     axios
     .post('https://syllatutors.com/syllatutor/getteacherclasses.php', {
       studentId:localStorage.getItem("studentIId"),
       teacherId:localStorage.getItem("teacherIId"),
       startDate:startDate,
       endDate:endDate
     })
     .then((response) => {
    setData((prevData) => ({ ...prevData, students: response.data.sessions }));
       console.log(response);
     })
     .catch((error) => {
       console.error('Error fetching students:', error);
     });

  
 
  };



  const closeModal = () => {
    setSelectedStudent(null);
    setSelectedTeacher('');
    setIsModalOpen(false);
  };



  const handleLogout = () => {
    // Add your logout logic here, e.g., clearing authentication tokens, etc.
    // After logout logic, you can redirect to the login page or perform other actions.
    // For now, we'll just set the logoutClicked state to true.
    setLogoutClicked(true);
  };
  const handlePasswordChange = () => {
    if (currentPassword && newPassword === confirmPassword) {
      const teacherId = localStorage.getItem('Admin');
      const passwordData = {
        id: teacherId,
        currentPassword,
        newPassword,
        role: 'Admin'
      };
      
      console.log(passwordData);
      axios
        .post('https://syllatutors.com/syllatutor/passwordchange.php', passwordData)
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            setIsPasswordChangeModalOpen(false);
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            Swal.fire({
              icon: 'success',
              title: 'Password Changed Successfully',
              text: 'Your password has been updated!',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Password Change Failed',
              text: 'Please check your current password and try again.',
            });
          }
        })
        .catch((error) => {
          console.error('Error changing password:', error);
        });
    } else {
      console.error('Invalid password change request');
    }
  };
  // If logoutClicked is true, you can redirect to the login page or perform other actions.
  
  if (logoutClicked) {
    localStorage.removeItem('Admin');
    return <Redirect to="/" />;
  }

  return (
    <div style={dashboardStyles}>
      <div style={headerStyles}>
        <h1>Welcome to the Admin Dashboard</h1>
        <Button variant="contained" color="secondary"  onClick={handleLogout}>
            Logout
          </Button>
          {/* <Button variant="contained" color="primary" onClick={() => setIsPasswordChangeModalOpen(true)}>
          Change Password
        </Button > */}
       
      </div>
      <h2>Teaching History</h2>

      <center><h3>{startDate+"----"+endDate+"   "} <br></br>Total session taken {data.students.length} </h3></center>
     <center> <input type="date" required name="startdate" onChange={handlestartdate}></input>
    &nbsp;  &nbsp; <input type="date" name="enddate" required onChange={handleenddate}></input>
    &nbsp;  &nbsp;    <Button variant="contained" color="primary" onClick={() => submitedate()}>
          Track history
        </Button ></center>
      <div style={tableStyles}>
        <center>
          <table>
          <thead>
            <tr>
              <th style={thStyles}>ID</th>
              <th style={thStyles}>Date</th>
              <th style={thStyles}>Time</th>
            
            </tr>
          </thead>
          <tbody>
            {data.students.map((student) => (
              <tr key={student.Id}>
                <td style={tdStyles}>{student.Id}</td>
                <td style={tdStyles}>{student.Dates}</td>
                <td style={tdStyles}>{student.Times}</td>
              </tr>
            ))}
          </tbody>
        </table></center>
      </div>
    
    </div>
  );
}

export default TrackTeacher;
