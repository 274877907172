import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';


const dashboardStyles = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  marginTop: '20px',
};

const headerStyles = {
  backgroundColor: '#333',
  color: '#fff',
  textAlign: 'center',
  padding: '20px',
};

const tableStyles = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
  overflowX: 'auto',
};

const thStyles = {
  backgroundColor: '#333',
  color: '#fff',
  padding: '10px',
  textAlign: 'left',
};

const tdStyles = {
  border: '1px solid #ccc',
  padding: '10px',
};

const buttonStyles = {
  backgroundColor: 'blue',
  color: '#fff',
  border: 'none',
  padding: '5px 10px',
  cursor: 'pointer',
  whiteSpace: 'nowrap'
};

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    border: 'none',
  },
};

function TeacherList() {
  const history = useHistory();
  const [data, setData] = useState({ students: [], teachers: [] });
  const [teacherData, setteacherData] = useState({ teacherData: [], studentData: [] });
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [logoutClicked, setLogoutClicked] = useState(false); // State to handle logout
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  useEffect(() => {
    const studentId = localStorage.getItem('studentId');
    const teacherId = localStorage.getItem('teacherId');
    const adminId = localStorage.getItem('Admin');
  
    if (studentId) {
      history.push('/studentdashboard');
    } else if (teacherId) {
      history.push('/teacherdashboard');
    } else if (adminId) {
      history.push('/teacherlist');
    } else {
      history.push('/');
    }
    // Fetch students data
    axios
      .post('https://syllatutors.com/syllatutor/getstudentandteachers.php', {
        studentId:localStorage.getItem("studentIId"),
        action: 'getTeachers',
      })
      .then((response) => {
       setData((prevData) => ({ ...prevData, students: response.data.teachers }));
        console.log(response);
      })
      .catch((error) => {
        console.error('Error fetching students:', error);
      });

    // Fetch teachers data
    axios
      .post('https://syllatutors.com/syllatutor/getstudentandteachers.php', {
        action: 'getTeachers',
      })
      .then((response) => {
        setData((prevData) => ({ ...prevData, teachers: response.data.teachers }));
      })
      .catch((error) => {
        console.error('Error fetching teachers:', error);
      });
  }, []);

    const assignTeacher = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  const deleteteacher = (id,stid) => {
    if (id) {
      const assignmentData = {
        studentId:stid,
        id: id,
        action: 'studentdeleteconnection',
      };
      console.log(assignmentData);
    axios
    .post('https://syllatutors.com/syllatutor/delete.php', assignmentData )
    .then((response) => {
      console.log('Teacher assigned successfully:', response.data);
       if(response.data=="Success"){
      
       
        Swal.fire({
          icon: 'success',
          title: 'Teacher deleted Successful',
          text: 'Teacher deleted!',
        });}
        else{
          Swal.fire({
            icon: 'error',
            title: "Contact Admin",
            text: "Contact Admin",
          });

        }
 
    })
    .catch((error) => {
      console.error('Error deleting student:', error);
    });
  }}
  ;

  const closeModal = () => {
    setSelectedStudent(null);
    setSelectedTeacher('');
    setIsModalOpen(false);
  };

  const handleTeacherChange = (event) => {
    setSelectedTeacher(event.target.value);
  };

  const handleLogout = () => {
    // Add your logout logic here, e.g., clearing authentication tokens, etc.
    // After logout logic, you can redirect to the login page or perform other actions.
    // For now, we'll just set the logoutClicked state to true.
    setLogoutClicked(true);
  };
  const handlePasswordChange = () => {
    if (currentPassword && newPassword === confirmPassword) {
      const teacherId = localStorage.getItem('Admin');
      const passwordData = {
        id: teacherId,
        currentPassword,
        newPassword,
        role: 'Admin'
      };
      
      console.log(passwordData);
      axios
        .post('https://syllatutors.com/syllatutor/passwordchange.php', passwordData)
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            setIsPasswordChangeModalOpen(false);
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            Swal.fire({
              icon: 'success',
              title: 'Password Changed Successfully',
              text: 'Your password has been updated!',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Password Change Failed',
              text: 'Please check your current password and try again.',
            });
          }
        })
        .catch((error) => {
          console.error('Error changing password:', error);
        });
    } else {
      console.error('Invalid password change request');
    }
  };
  // If logoutClicked is true, you can redirect to the login page or perform other actions.
  
  if (logoutClicked) {
    localStorage.removeItem('Admin');
    return <Redirect to="/" />;
  }

  return (
    <div style={dashboardStyles}>
      <div style={headerStyles}>
        <h1>Welcome to the Admin Dashboard</h1>
        <Button variant="contained" color="secondary"  onClick={handleLogout}>
            Logout
          </Button>
          {/* <Button variant="contained" color="primary" onClick={() => setIsPasswordChangeModalOpen(true)}>
          Change Password
        </Button > */}
      </div>
      <h2>Categories</h2>

      <h3>Students</h3>
      <div style={tableStyles}>
        <table>
          <thead>
            <tr>
              <th style={thStyles}>ID</th>
              <th style={thStyles}>Firstname</th>
              <th style={thStyles}>Lastname</th>
              <th style={thStyles}>Email</th>
              <th style={thStyles}>Actions</th>
              <th style={thStyles}>Show teachers</th>
            </tr>
          </thead>
          <tbody>
            {data.students.map((student) => (
              <tr key={student.Id}>
                <td style={tdStyles}>{student.Id}</td>
                <td style={tdStyles}>{student.Teacher_First_Name}</td>
                <td style={tdStyles}>{student.Teacher_Last_Name}</td>
                <td style={tdStyles}>{student.Email}</td>
                <td style={tdStyles}>
                  <button style={buttonStyles} onClick={() => deleteteacher(student.Id, localStorage.getItem('studentIId'))}>
                    Delete Teacher
                  </button>
                </td>
                <td style={tdStyles}>


                  <Link style={buttonStyles}  to="/trackteacher"   onClick={() => localStorage.setItem("teacherIId",student.Id)} >
                Show Details</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    
    </div>
  );
}

export default TeacherList;
