import React from 'react';
import Footer from '../../Common/Footer';

function Tos() {
  const colStyle = {
    backgroundColor: "#ebeadf",
    paddingTop: "120px",
  };

  const headerStyle = {
    marginTop: "20%",
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: "Raleway, sans-serif",
  };

  const mainTextStyle = {
    fontSize: "16px",
    fontFamily: "Raleway, sans-serif",
    marginTop: "20%",
  };

  return (
    <React.Fragment>
      <section style={colStyle}>
        <div className="row">
          <div className="col-md-6">
            <h2 style={headerStyle}>Terms of Service</h2>
          </div>
          <div className="col-md-6">
            <ol style={mainTextStyle}>
              <li>
                <strong>Acceptance of Terms</strong> – By accessing or using any services provided by the Agency, you agree to be bound by this Agreement. If you do not agree to these terms, you may not use our services.
              </li>
              <li>
                <strong>Services Offered</strong> – The Agency offers tutoring services for educational purposes. We strive to provide high-quality tutoring tailored to the specific needs of our clients. However, we cannot guarantee specific outcomes or improvements in academic performance.
              </li>
              <li>
                <strong>Tutoring Sessions</strong> – The Agency will make reasonable efforts to match students with suitable tutors based on their needs, subject areas, and availability. We cannot guarantee the availability of any specific tutor or the exact timing of tutoring sessions. Tutoring sessions may be conducted in person, online, or through other agreed-upon means.
              </li>
              <li>
                <strong>Fees and Payments</strong> – The Agency’s fees for tutoring services are outlined in our pricing and payment terms. You agree to pay the applicable fees for the services rendered by the Agency. Payments should be made according to the agreed-upon schedule and method. Failure to make timely payments may result in the suspension or termination of services.
              </li>
              <li>
                <strong>Cancellation and Rescheduling</strong> – Both students and tutors must provide reasonable notice for any cancellation or rescheduling of tutoring sessions. Failure to provide sufficient notice may result in fees being charged for the missed session. The Agency reserves the right to reschedule or cancel sessions if necessary and will make reasonable efforts to notify the affected parties.
              </li>
              <li>
                <strong>Code of Conduct</strong> – All users of the Agency’s services are expected to conduct themselves in a respectful and professional manner. Harassment, discrimination, or any form of inappropriate behavior will not be tolerated. The Agency reserves the right to terminate services if a user engages in such behavior.
              </li>
              <li>
                <strong>Intellectual Property</strong> – All intellectual property rights, including but not limited to content, materials, and resources provided by the Agency, remain the property of the Agency or its licensors. Users may not reproduce, distribute, or modify any copyrighted materials without prior written consent from the Agency.
              </li>
              <li>
                <strong>Limitation of Liability</strong> – The Agency shall not be liable for any indirect, incidental, special, or consequential damages arising from the use or inability to use our services, including but not limited to any loss of data or loss of profits.
              </li>
              <li>
                <strong>Privacy</strong> – The Agency respects your privacy and handles personal information in accordance with applicable privacy laws. Please refer to our Privacy Policy for more information on how we collect, use, and protect your personal data.
              </li>
              <li>
                <strong>Modification of Terms</strong> – The Agency reserves the right to modify or update these terms of service at any time. Any changes will be effective immediately upon posting on our website. It is your responsibility to review these terms periodically.
              </li>
              <li>
                <strong>Governing Law</strong> – This Agreement shall be governed by and construed in accordance with the laws of UK Government. Any disputes arising from or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in UK.
              </li>
            </ol>
            <p>
              By using the services provided by the Agency, you acknowledge that you have read, understood, and agreed to be bound by these terms of service. If you have any questions or concerns, please contact us at <a href="mailto:syllatutors@outlook.com">syllatutors@outlook.com</a>.
            </p>
            <p>Last updated: 23/06/2023</p>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

export default Tos;
