import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom';
function SuccessPage() {
  const history = useHistory();
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  const studentId = localStorage.getItem('studentId');
  const quantity = parseInt(localStorage.getItem('quantity'));
   console.log("qua",quantity);

  useEffect(() => {


    const teacheriId = localStorage.getItem('teacherId');
    const adminId = localStorage.getItem('Admin');
  
    if (studentId) {
      history.push('/success');
    } else if (teacheriId) {
      history.push('/');
    } else if (adminId) {
      history.push('/');
    } else {
     history.push('/');
    }





    // Create an object with the parameters you want to send
    const data = {
      id: studentId,
      quantity: quantity,
    };
if(quantity){
    // Make an Axios POST request
    axios.post('https://syllatutors.com/syllatutor/buysession.php', data)
      .then(response => {
        // Handle the response here, e.g., display a message to the user
        console.log('Response from server:', response.data.message);
        if(response.data.message=="Session bought successfully."){
          // Display a success message using SweetAlert
          Swal.fire({
            icon: 'success',
            title: 'Congratulation you have  Successfuly bouhgt session',
            text: 'Success',
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.setItem('quantity',0);
              // Perform another action here, e.g., redirect to another page
              localStorage.removeItem('studentId');
              window.location.href = '/studentlogin';
            }
          });
        }
      })
      .catch(error => {
        // Handle errors here, e.g., show an error message
        console.error('Error:', error);
      });}
      else{
           // Display a success message using SweetAlert
           Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Press ok to go home',
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.setItem('quantity',0);
              // Perform another action here, e.g., redirect to another page
             // window.location.href = '/';
            }
          });
      }




  }, [studentId, quantity]);

  return (
    <div>
      
  
      {/* You can add more content or actions here */}
    </div>
  );
}

export default SuccessPage;
