import React, { Component } from 'react';
import Footer from '../../Common/Footer';

class Pricing extends Component {


  render() {
    const colStyle = {
        backgroundColor: "#ebeadf",
        padding: "20px",
      paddingTop: "80px",
      };

    return (
      <React.Fragment>
        <section style={colStyle}>
          <div className='container'>
            <center style={{marginTop:"10%"}}> <h1>Pricing</h1></center>
            <div className="row" style={{marginTop:"10%"}}>
              <div className="col-md-6" style={{marginTop:"5%"}

              }>
                <h1 >Free<br></br></h1>

15 minute consultation

              </div>
                  <div className="col-md-6" style={{marginTop:"5%"}

              }>
              We understand the importance of trust and assurance when choosing a tutoring service, which is why we invite you to try our services at no cost. Our free trial is designed to give you a firsthand experience of our exceptional tutoring and the positive impact it can have on your academic success.
             
              </div>
              
                  <div className="col-md-6" style={{marginTop:"5%"}

              }>
        
              <h1 >Free<br></br></h1>


30 minute free trial

              </div>
                  <div className="col-md-6" style={{marginTop:"5%"}

              }>
                Are you uncertain about your academic needs or unsure where to begin? Take advantage of our complimentary 15-minute consultation, designed to help you gauge your requirements and discover how we can help. We understand that every student is unique, and this consultation is your opportunity to gain clarity and chart a path towards success.
           
              </div>
                  <div className="col-md-6" style={{marginTop:"5%"}

              }>
                <h1>£25</h1>

1x one hour lesson

            </div>
          
                  <div className="col-md-6" style={{marginTop:"5%"}

              }>
              Once you have contacted us and decided to go ahead with booking sessions with our tutors please press the book now button below to purchase sessions.
            
              </div>
             
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Pricing;
