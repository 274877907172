import React, { Component } from "react";
import Footer from '../../Common/Footer';

class PrivacyPolicy extends Component {
  render() {
    const colStyle = {
      backgroundColor: "#ebeadf",
     // Set left and right margins to 10%
      display: "flex",
      justifyContent: "center",
      padding: "20px",
      paddingTop: "80px",
    };
    const colStyle1 = {
        backgroundColor: "#ebeadf",
        padding: "20px", // Add padding to create space within the "cols" section
        margin: "0 10%", // Set left and right margins to 10%
        display: "flex",
        justifyContent: "center",
      };
  

    return (
      <React.Fragment>
    <section style={colStyle}>
        <div style={colStyle1}>
          <div>
            <h2 style={{ fontWeight: 'bold', textAlign: "center"}}>Privacy Policy</h2>

            <strong>Last Updated:</strong> [23/06/2023]

            <p>
              Thank you for visiting SyllaTutors (“we,” “us,” or “our”) website. This Privacy Policy explains how we collect, use, disclose, and protect the personal information you provide when using our website. We are committed to protecting your privacy and ensuring the security of your personal information. Please read this Privacy Policy carefully to understand our practices regarding your personal data.
            </p>

            <ol>
              <li>
                <strong>Information We Collect</strong>
                <ol>
                  <li>
                    <strong>(a) Personal Information:</strong> When you register on our website, we may collect personal information such as your name, email address, contact number, and any other information you provide voluntarily.
                  </li>
                  <li>
                    <strong>(b) Usage Information:</strong> We may collect non-personal information about your interactions with our website, including IP addresses, browser type, device information, and browsing behavior.
                  </li>
                  <li>
                    <strong>(c) Cookies and Similar Technologies:</strong> We may use cookies and similar technologies to collect information about your browsing activities on our website. Cookies are small files that are stored on your device to enhance your browsing experience. You can manage your cookie preferences through your browser settings.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Use of Information</strong>
                <ol>
                  <li>
                    <strong>(a) Provide Services:</strong> We may use your personal information to provide our tutoring services, including scheduling lessons, communicating with you, and delivering educational content.
                  </li>
                  <li>
                    <strong>(b) Improve User Experience:</strong> We may analyze usage data to improve our website’s functionality, user interface, and overall user experience.
                  </li>
                  <li>
                    <strong>(c) Communication:</strong> We may send you administrative and promotional emails related to our services. You can opt out of promotional emails at any time.
                  </li>
                  <li>
                    <strong>(d) Legal Compliance:</strong> We may use or disclose your personal information to comply with applicable laws, regulations, or legal processes.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Information Sharing</strong>
                <ol>
                  <li>
                    <strong>(a) Tutors and Tutees:</strong> If you are a tutor or tutee, we may share your personal information with the relevant parties involved in scheduling and conducting tutoring sessions.
                  </li>
                  <li>
                    <strong>(b) Service Providers:</strong> We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting business activities, or providing services on our behalf. These service providers have access to your personal information only to perform their specific tasks and are obligated to keep your information confidential.
                  </li>
                  <li>
                    <strong>(c) Legal Requirements:</strong> We may disclose your personal information if required by law, court order, or government regulation.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Data Security</strong>
                <br></br>   We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee absolute security of your information.
              </li>
              <li>
                <strong>Third-Party Links</strong>
                <br></br>    Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites. We encourage you to review the privacy policies of any third-party websites you visit.
              </li>
              <li>
                <strong>Children’s Privacy</strong>
                <br></br>   Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have collected personal information from a child under 13, please contact us, and we will promptly remove the information from our records.
              </li>
              <li>
                <strong>Changes to the Privacy Policy</strong>
                <br></br> We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. The updated policy will be posted on our website, and the “Last Updated” date will indicate the effective date of the revised Privacy Policy.
              </li>
              <li>
                <strong>Contact Us</strong>
               <br></br> If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at [email address].<br></br>
              
                By using our website, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.
              <br></br><br></br>
                Our Postal Address:<br></br> 106 Spitfire Road,<br></br> CB236FN, <br></br>Cambourne
              </li>
            </ol>
          </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;
