import React, { Component } from "react";
import friendsimage from '../assets/img/07/image-asset-2.jpeg';
import './LastItem.css'; // Import the CSS file at the top of your component file
const services = [
  {
    title: "We’re here to help you thrive",
    description: "After years of personal and professional experience, we founded SyllaTutors in an effort to help students achieve their highest possible grades and study the subjects they want at the Universities they want. We provide 1:1 study sessions, workshops throughout the year, and personal advice from experts for University Applications including Medical Interview Preparation.",
  },
];


class Services extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="page-section" id="services">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                {services.map((service, index) => {
                  return (
                    <div className="service-item" key={index}>
                      <h2  className="titlelastitem">{service.title}</h2>
                      <p style={{textAlign:"justify"}}>{service.description}</p>
                  
                    </div>
                  );
                })}
              </div>
              <div className="col-md-6">
                <img src={friendsimage} alt="Friends" width={'100%'}  height={'100%'} />
                
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
