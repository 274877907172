import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Swal from 'sweetalert2';

const dashboardStyles = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  marginTop: '20px',
};

const headerStyles = {
  backgroundColor: '#333',
  color: '#fff',
  textAlign: 'center',
  padding: '20px',
};

const tableStyles = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
  overflowX: 'auto',
};

const thStyles = {
  backgroundColor: '#333',
  color: '#fff',
  padding: '10px',
  textAlign: 'left',
};

const tdStyles = {
  border: '1px solid #ccc',
  padding: '10px',
};

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    border: 'none',
  },
};

function AdminDashboard() {
  const [data, setData] = useState({ students: [], teachers: [] });
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedTeacherdate, setSelectedTeacherdate] = useState('');
  const [selectedTeachertime, setSelectedTeachertime] = useState('');
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const history = useHistory();

  useEffect(() => {
    const studentId = localStorage.getItem('studentId');
    const teacheriId = localStorage.getItem('teacherId');
    const adminId = localStorage.getItem('Admin');
  
    if (studentId) {
      history.push('/studentdashboard');
    } else if (teacheriId) {
      history.push('/teacherdashboard');
    } else if (adminId) {
      history.push('/teacherlist');
    } else {
      history.push('/');
    }
    // Fetch students data
    const teacherId = localStorage.getItem('teacherId');
    axios
      .post('https://syllatutors.com/syllatutor/getallstudentsforteacher.php', {
        teacherId: teacherId,
      })
      .then((response) => {
        console.log(response.data);
        setData((prevData) => ({ ...prevData, students: response.data.students }));
      })
      .catch((error) => {
        console.error('Error fetching students:', error);
      });
  }, []);

  const assignTeacherToStudent = () => {
    const teacherId = localStorage.getItem('teacherId');
    console.log(selectedTeachertime);
    if (selectedTeacherdate && selectedTeachertime) {
      const assignmentData = {
        Student_Id: String(selectedStudent.Id),
        Teacher_Id: teacherId,
        Times: selectedTeachertime,
        Dates: selectedTeacherdate,
      };

if(selectedTeachertime<=5){
      axios
        .post('https://syllatutors.com/syllatutor/sessions.php', assignmentData)
        .then((response) => {
          console.log(response);
       
          setSelectedTeacherdate(null);
          setSelectedTeachertime(null);
          closeModal();
          Swal.fire({
            icon: 'success',
            title: "Response",
            text: response.data.message,
          });
       
        //  if(response.data.message=="Session added successfully and deducted successfully"){
        //   setSelectedTeacherdate(null);
        //   setSelectedTeachertime(null);
        //   closeModal();
        //   Swal.fire({
        //     icon: 'success',
        //     title: response.data.message,
        //     text: 'Your session added!',
        //   });
        // }
        //   else{
        //     Swal.fire({
        //       icon: 'error',
        //       title: 'already  session consumed',
        //       text: 'You have already consumed session today!',
        //     });

        //   }
         
        })
        .catch((error) => {
          console.error('Error assigning session:', error);
        });
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Maximum session is 5 in a day!',
          text: 'Your session added!',
        });


      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Please enter date and sessions!',
        text: 'Your session added!',
      });
      console.error('No session selected');
    }
  };

  const assignTeacher = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedStudent(null);
    setSelectedTeacher('');
    setIsModalOpen(false);
  };

  const handleTeacherChangeDate = (event) => {
    setSelectedTeacherdate(event.target.value);
  };

  const handleTeacherChangeTime = (event) => {
    setSelectedTeachertime(event.target.value);
  };

  const handlePasswordChange = () => {
    if (currentPassword && newPassword === confirmPassword) {
      const teacherId = localStorage.getItem('teacherId');
      const passwordData = {
        id: teacherId,
        currentPassword,
        newPassword,
        role: 'Teacher'
      };
      console.log(passwordData);
      axios
        .post('https://syllatutors.com/syllatutor/passwordchange.php', passwordData)
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            setIsPasswordChangeModalOpen(false);
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            Swal.fire({
              icon: 'success',
              title: 'Password Changed Successfully',
              text: 'Your password has been updated!',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Password Change Failed',
              text: 'Please check your current password and try again.',
            });
          }
        })
        .catch((error) => {
          console.error('Error changing password:', error);
        });
    } else {
      console.error('Invalid password change request');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('teacherId');
    localStorage.removeItem('quantity');
    history.push('/');
  };

  return (
    <div style={dashboardStyles}>
      <div style={headerStyles}>
        <h1>Welcome to the Teacher Dashboard</h1>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
        <Button variant="contained" color="primary" onClick={() => setIsPasswordChangeModalOpen(true)}>
          Change Password
        </Button>
      </div>
      <h2>Categories</h2>

      <h3>Students</h3>
      <div style={tableStyles}>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell style={thStyles}>ID</TableCell>
            <TableCell style={thStyles}>Firstname</TableCell>
            <TableCell style={thStyles}>Lastname</TableCell>
            <TableCell style={thStyles}>Email</TableCell>
            <TableCell style={thStyles}>Total Sessions</TableCell>
            <TableCell style={thStyles}>Remaining Sessions</TableCell>
            <TableCell style={thStyles}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.students.map((student) => (
            <TableRow key={student.Id}>
              <TableCell style={tdStyles}>{student.Id}</TableCell>
              <TableCell style={tdStyles}>{student.Student_First_Name}</TableCell>
              <TableCell style={tdStyles}>{student.Student_Last_Name}</TableCell>
              <TableCell style={tdStyles}>{student.Email}</TableCell>
              <TableCell style={tdStyles}>{student.Sessions}</TableCell>
              <TableCell style={tdStyles}>{student.RemSessions}</TableCell>
              <TableCell style={tdStyles}>
                <Button
                  variant="contained"
                  color="primary"

                  onClick={() => assignTeacher(student)}
                >
                  Consume Session
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
</div>
      {/* Modal Dialog */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
      >
        {selectedStudent && (
          <>
            <h2>Detail of Session</h2>
            <p>ID: {selectedStudent.Id}</p>
            <p>Firstname: {selectedStudent.Student_First_Name}</p>
            <p>Lastname: {selectedStudent.Student_Last_Name}</p>
            <div style={{ marginBottom: '10px' }}>Select a Date:</div>
            <input type="date" onChange={handleTeacherChangeDate}></input>
            <div style={{ marginBottom: '10px' }}>Enter hours (Maximum hours for each day is 5):</div>
            <input type="number" onChange={handleTeacherChangeTime}></input>
            {/* <input type="time" onChange={handleTeacherChangeTime}></input> */}
            <div style={{ marginBottom: '10px' }}></div>
            <Button
              variant="contained"
              color="primary"
              onClick={assignTeacherToStudent}
            >
              Consume Session
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={closeModal}
            >
              Close
            </Button>
          </>
        )}
      </Modal>

      {/* Password Change Modal */}
      <Modal
        isOpen={isPasswordChangeModalOpen}
        onRequestClose={() => setIsPasswordChangeModalOpen(false)}
        style={customModalStyles}
      >
        <h2>Change Password</h2>
        <div>
          <label>Current Password:</label>
          <input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
        </div>
        <div>
          <label>New Password:</label>
          <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
        <Button variant="contained" color="primary" onClick={handlePasswordChange}>
          Save
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setIsPasswordChangeModalOpen(false)}>
          Cancel
        </Button>
      </Modal>
    </div>
  );
}

export default AdminDashboard;
