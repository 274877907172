import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Pagewrapper extends Component {
  render() {
    const boldText = { fontWeight: 'bold', whiteSpace: 'nowrap', color: 'white', margin: '5px', FontFace: 'Raleway' };
    const boldText1 = { fontWeight: 'bold',  color: 'white', FontFace: 'Raleway' };

    const centerMenuStyle = {
    
      alignItems: 'right', // Center vertically
    };
    const facebookLink = "https://www.facebook.com/profile.php?id=100091937411234%20";
    const instagramLink = "https://instagram.com/syllatutors?igshid=MjEwN2IyYWYwYw==";

    const transparentBlackBackground = {
      background: 'rgba(0, 0, 0, 0.6)', // 0.7 represents the alpha (transparency) value
    };

    return (
     <>
        <nav className="navbar navbar-expand-md navbar-alignItems-center fixed-top" id="mainNav" style={transparentBlackBackground}>
        
          <div className='row' >
        
          <div className="col-lg-4">
            <a className="navbar-brand js-scroll-trigger" href="/" style={boldText1}>syllatutors</a>
            </div>
            <div className="col-lg-8" >
            <button
              className="navbar-toggler navbar-toggler-right"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menu
              <i className="fas fa-bars ml-1" />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav " style={centerMenuStyle}>
                <li className="nav-item">
                  <Link style={boldText} className="nav-link js-scroll-trigger" to="/aboutus">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link style={boldText} className="nav-link js-scroll-trigger" to="/pricing">
                    Pricing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link style={boldText} className="nav-link js-scroll-trigger" to="/tutorapplication">
                    Tutor Application
                  </Link>
                </li>

                <li className="nav-item">
                  <Link style={boldText} className="nav-link js-scroll-trigger" to="/contactus">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item dropdown"> {/* Create a dropdown menu for login options */}
                  <a
                    className={'nav-link dropdown-toggle'}
                    href="#"
                    id="loginDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={boldText}
                  >
                    Login
                  </a>
                  <div className="dropdown-menu" aria-labelledby="loginDropdown">
                    <Link className="dropdown-item" to="/teacherlogin">Login as Teacher</Link>
                    <Link className="dropdown-item" to="/studentlogin">Login as Student</Link>
                    <Link className="dropdown-item" to="/login">Login as Admin</Link>
                  </div>
                </li>
                <i style={boldText} >
                  <a href={facebookLink}>
                    <i className="fab fa-facebook" style={boldText} ></i>
                  </a>
                </i>
                <i style={boldText} >
                  <a href={instagramLink}>
                    <i className="fab fa-instagram" style={boldText} ></i>
                  </a>
                </i>
              </ul>
            </div>
            </div>
          </div>
          
        </nav>
        {this.props.children}
        </>
    );
  }
}
