
import React, { Component, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import background from '../assets/img/07/image-asset.jpeg';
import './Header.css'; // Import the CSS file at the top of your component file


const Header = (props) => {
  const history = useHistory();

  useEffect(() => {
    const studentId = localStorage.getItem('studentId');
    const teacherId = localStorage.getItem('teacherId');
    const adminId = localStorage.getItem('Admin');
    localStorage.removeItem('quantity');
    if (studentId) {
      history.push('/studentdashboard');
    } else if (teacherId) {
      history.push('/teacherdashboard');
    } else if (adminId) {
      history.push('/admin');
    } else {
      history.push('/');
    }
  }, [history]);


    return (
      <React.Fragment>
       
   
          <div className="header-container" style={{ backgroundImage: `url(${background})` }}>
          <h2 className="title">Professional Tuition Services for <br /> All Subjects and Ages</h2>
          <br />
          <br />
          <div className="btitle">07555442961</div>
          <br />
          <div className="btitle">info@syllatutors.com</div>
          <center>
            <a
              className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
              style={{ backgroundColor: '#DEB15D' }}
              href="/contactus"
            >
              Contact Us
            </a>
          </center>
        </div>
        
        
      </React.Fragment>
    );
  }


export default Header;
