import React, { useState } from 'react';
import Modal from 'react-modal';
import Footer from '../../Common/Footer';
import team from '../../assets/portfolio/team.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Input, Button, Select,Checkbox } from 'antd';

const { Option } = Select;
const colStyle = {
  backgroundColor: "#ebeadf",
  padding: "20px",
      paddingTop: "80px",
};

const headerStyle = {
  marginTop: "10%",
};

const mainTextStyle = {
  fontSize: "70px",
  fontFamily: "Raleway, sans-serif",
 
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function TA() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onFinish = (values) => {
    // Implement your form submission logic here
    console.log('Success:', values);
    closeModal(); // Close the modal after submission
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <React.Fragment>
     <section style={colStyle}>
  <div className="container">
    <div className="row" style={headerStyle}>
      {/* For small and medium screens, image comes first */}
      <div className="col-md-6 order-md-1">
        <img 
          src={team} 
          alt="team" 
          className="img-fluid" // This Bootstrap class ensures the image is responsive
        />
      </div>

      {/* Content column */}
      <div className="col-md-6 order-md-2">
        <div style={mainTextStyle}>Join the team</div>
        <p style={{ textAlign: 'justify', fontSize: "16px", fontFamily: "Raleway, sans-serif" }}>
                Syllatutors is thrilled to present you with an exciting opportunity to join hundreds of tutors in our dynamic educational organization. We are on a mission to provide exceptional learning experiences for students, and we need your expertise to make it happen. As a tutor at Syllatutors, you’ll have the chance to ignite a love for learning, inspire young minds, and shape the future. We offer a supportive and collaborative environment where your skills will be recognized, honed, and celebrated. Join our team of dedicated educators and unlock your teaching potential with Syllatutors. Together, let’s create a brighter educational landscape for students across the globe.
              </p>
        <br></br>
        <br></br>
        <center>
          <button
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "10px 20px",
              textDecoration: "none",
              borderRadius: "5px",
            }}
            onClick={openModal}
          >
            Join Us
          </button>
        </center>
      </div>
    </div>
  </div>
</section>


      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Join Us Modal"
      >
        {/* Add your modal content here */}
        <h2>New Tutor</h2>
        <Form
         layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          
         
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please input your first name!' }]}
          ><Input />
          </Form.Item>
      

            <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: 'Please input your Gender!' }]}
          >
            <Select placeholder="Select Male or Female">
      <Option value="male" >Male</Option>
      <Option value="female">Female</Option>
    </Select>
          </Form.Item>


          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: 'Please input your Phone!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Tell us in 3-4 sentences what experience you have in tutoring. If you have no experience then tell us why do you want to become a tutor."
            name="description"
            rules={[{ required: true, message: 'Please enter description' }]}
          >
            <Input />
          </Form.Item>


          <Form.Item
            label="Subjects you would like to teach "
            name="stt"
            rules={[{ required: true, message: 'Please enter subject' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Current Occupation"
            name="occupation"
            rules={[{ required: true, message: 'Please input occupation' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Highest Completed Level of Education"
            name="levelofeducation"
            rules={[{ required: true, message: 'Please input level of education' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
    label="Are you allowed to work with minors?"
    name="workwithminors"
    rules={[{ required: true, message: 'Please select Yes or No' }]}
  >
    <Select placeholder="Select Yes or No">
      <Option value="Yes" >Yes</Option>
      <Option value="No">No</Option>
    </Select>
  </Form.Item>

  <Form.Item
    label="Do you confirm that you have read and accepted our terms and conditions and data protection guidelines? "
  >
    {/* You can leave this empty if it's just a confirmation statement without user input */}
  </Form.Item>

  <Form.Item
    label="Our terms and conditions and data protection guidelines can be found in the footer at the bottom of our website."
    name="protection"
    rules={[{ required: true, message: 'Please select Yes or No' }]}
  >
    <Select placeholder="Select Yes or No">
      <Option value="Yes">Yes</Option>
      <Option value="No">No</Option>
    </Select>
  </Form.Item>

  <Form.Item
    label="Do you confirm that you are at least 18 years old?"
    name="ageconfirm"
    rules={[{ required: true, message: 'Please select Yes or No' }]}
  >
    <Select placeholder="Select Yes or No">
      <Option value="Yes">Yes</Option>
      <Option value="No">No</Option>
    </Select>
  </Form.Item>

  <Form.Item
    label="Do you have an enhanced DBS?"
    name="enhancedDBS"
    rules={[{ required: true, message: 'Please select Yes or No' }]}
  >
    <Select placeholder="Select Yes or No">
      <Option value="Yes">Yes</Option>
      <Option value="No">No</Option>
    </Select>
  </Form.Item>





          

          {/* Add other form fields based on your requirements */}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit"  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "10px 20px",
                    textDecoration: "none",
                    borderRadius: "5px",
                    height:"50px",
                    width:"100px"
                  }}>
              Join Us
            </Button>
          </Form.Item>
        </Form>
      

      </Modal>

      <Footer />
    </React.Fragment>
  );
}

export default TA;
