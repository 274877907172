import React, { Component } from 'react';
import Footer from '../../Common/Footer';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import './AboutUs.css'; // Import the CSS file at the top of your component file
class SyllaTutorsComponent extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      openDropdownIndex: -1,
    };
  }

  toggleDropdown = (index) => {
    if (this.state.openDropdownIndex === index) {
      this.setState({ openDropdownIndex: -1 });
    } else {
      this.setState({ openDropdownIndex: index });
    }
  };

  render() {
    const colStyle = {
      backgroundColor: "#ebeadf",
      padding: "20px",
      paddingTop: "80px",
    };

    const headerStyle = {
    
    };

    const mainTextStyle = {
      fontSize: "18px",
      fontFamily: "Raleway, sans-serif",
      marginTop: "20%",
    };

    const detailsArray = [
      {
        heading: "Expert Tutors",
        details: "We are a team of tutors - each experts in our respective fields. Our tutors have undergone rigorous screening and possess in-depth knowledge of the subjects they teach. With their guidance, students can gain a deeper understanding of the material and excel in their studies.",
      },
      {
        heading: "Personalized Approach",
        details: "We believe that every student is unique and has different learning needs. That's why we tailor our tutoring sessions to cater to individual requirements. Whether a student needs help with a specific topic, wants to improve their study skills, or requires ongoing support, our tutors are here to provide personalized attention and guidance.",
      },


      {
        heading: "Academic Excellence",
        details: "As medical students, we have a strong academic background and a deep understanding of the importance of excellence in education. We bring this commitment to our tutoring company, ensuring that our students receive top-notch instruction and support to achieve outstanding academic results.",
      },
      {
        heading: "Flexible Scheduling",
        details: "We understand that students have busy schedules filled with classes, extracurricular activities, and other commitments. Therefore, we offer flexible scheduling options to accommodate their needs. Whether it's during weekdays, weekends, or evenings, we strive to find the best tutoring slots that fit seamlessly into students' routines.",
      },



      {
        heading: "Holistic Approach",
        details: "We believe that education goes beyond textbooks and exams. Our tutoring services encompass a holistic approach, focusing not only on academic success but also on fostering critical thinking skills, problem-solving abilities, and building confidence. We aim to develop well-rounded individuals who are equipped to face any academic challenge.",
      },
    ]

    return (
      <React.Fragment>
        <section style={colStyle}>
          <div className='container'>
            <div className="row">
              <div className="col-md-7">
                <h1 className="titleaboutus">Why Choose Us?</h1>
                <h5>
                  At SyllaTutors, we are passionate about education and empowering students to achieve their full potential. <br></br>Founded by two dedicated medical students, we understand the challenges and demands of academic life. <br></br>Our mission is to provide exceptional tutoring services that support students in their educational journey and help them succeed.
                </h5>
              </div>
              <div className="col-md-5" style={{marginTop:100}}>
            
                {detailsArray.map((item, index) => (
                  // <div key={index}>
                  //   <div
                  //     className="dropdown-heading"
                  //     onClick={() => this.toggleDropdown(index)}
                  //     style={mainTextStyle}
                  //   >
                  //         <i className={`fas fa-chevron-${this.state.openDropdownIndex === index ? 'up' : 'down'}`}></i> {item.heading}
                   
                  //     {item.heading}
                  //   </div>
                  //   {this.state.openDropdownIndex === index && (
                  //     <div>
                  //       <p>{item.details}</p>
                  //     </div>
                  //   )}
                  // </div>
                  <>         
                    <Accordion style={{  marginTop: 65, background: "#ebeadf"}}>
                           <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                >
                    <Typography
                        style={{
                            fontWeight: 10,
                            fontFamily:'Raleway',  
                        }}
                    >
                        {item.heading}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography  style={{
                           
                            fontFamily:'Raleway',
                            textAlign:'justify'
                        }}>{item.details} </Typography>
                </AccordionDetails>
                </Accordion>
                </>

                  
                ))}
               
                
            
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default SyllaTutorsComponent;
