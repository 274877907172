import React, { useState } from "react";
import Footer from '../../Common/Footer';
import Modal from 'react-modal';
import image1 from "../../assets/whatweoffer.jpg";
import image2 from "../../assets/whatweoffer2.jpg";
import image3 from "../../assets/whatweoffer3.jpg";
import icon from "../../assets/portfolio/syicon.png";
import axios from 'axios'
import './ContactUs.css'; // Import the CSS file at the top of your component file

import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Input, Button, Select,Checkbox } from 'antd';

const { Option } = Select;
const colStyle = {
  backgroundColor: "#ebeadf",
  padding: "20px",
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function ContactUs (){
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onFinish = async (values) => {
    try {
      // Make an Axios POST request to your PHP script
      const response =  await axios.post('https://syllatutors.com/syllatutor/login_student.php', values);
  
      // Log the response from the server (you can customize this based on your needs)
      console.log('Server Response:', response.data);
  
      // Close the modal after successful submission
      closeModal();
    } catch (error) {
      // Handle errors if the request fails
      console.error('Error:', error.message);
    }
    // Implement your form submission logic here
    console.log('Success:', values);
    closeModal(); // Close the modal after submission
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
 
    const containerStyle = {
      backgroundColor: "#ebeadf",
      padding: "20px",
      paddingTop: "80px",
    };

    const headerStyle = {
      fontSize: "24px",
      fontWeight: "bold",
      fontFamily: "Raleway, sans-serif",
    };

    const contentStyle = {
      backgroundColor: "#ebeadf",
      margin: "1%",
    };

    const responsiveStyles = {
      // Define responsive styles here
      '@media (max-width: 768px)': {
        headerStyle: {
          fontSize: "20px",
        },
        // Add more responsive styles as needed
      },
    };

    return (
      <React.Fragment>
        <section style={containerStyle}>
          <div style={contentStyle}>
            <h2 style={{ ...headerStyle, ...responsiveStyles.headerStyle, textAlign: 'center', fontFamily: 'Raleway', fontSize:"24px", lineHeight:"29px", fontWeight:700}}>WHAT WE OFFER</h2>
            <br></br>
            <center><img src={image1} alt="What We Offer" 
            
            className="img-fluid" // This Bootstrap class ensures the image is responsive
            /><img src={image2} alt="What We Offer" 
            
            className="img-fluid" // This Bootstrap class ensures the image is responsive
            /><img src={image3} alt="What We Offer" 
            
            className="img-fluid" // This Bootstrap class ensures the image is responsive
            /></center>
            <br></br><center><button
              onClick={openModal}
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "10px 20px", // Add padding to make the button larger
              textDecoration: "none",
              borderRadius: "5px", // Add rounded corners
            }}
          >
            GET STARTED
          </button></center>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h2 style={{ ...headerStyle, ...responsiveStyles.headerStyle, textAlign: 'center', fontFamily: 'Raleway' }}>Or just want to get in touch?</h2>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                    <br></br>
                    <br></br>
                    <br></br>
                  Drop us a message or email with the following information:
                  <ul>
                    <li>Name of Student</li>
                    <li>Year of Student</li>
                    <li>Subjects for Tuition</li>
                    <li>Exam Boards for Subjects</li>
                  </ul>
                  <center>
                    <b>Phone</b>
                    <br></br>
                    +44 7772 113923
                    <br></br>
                    <b>Email</b>
                    <br></br>
                    info@syllatutors.com
                  </center>
                </div>
                <div className="col-md-6">
                  <center><img src={icon} alt="Icon" 
                   className="ImagePage" // This Bootstrap class ensures the image is responsive
                  /></center>
                </div>
              </div>
            </div>
          </div>
        </section>
           {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Join Us Modal"
      >
        {/* Add your modal content here */}
        <h2>New Student</h2>
        <Form
         layout="vertical"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          
         
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: 'Please input your first name!' }]}
          ><Input />
          </Form.Item>
      

            <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input />
          </Form.Item>
          
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Contact No."
            name="phone"
            rules={[{ required: true, message: 'Please input your Phone!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Message."
            name="message"
            rules={[{ required: true, message: 'Please enter description' }]}
          >
            <Input />
          </Form.Item>


          <Form.Item
            label="Student Year of School, Subjects for Tuition and Exam Board (If applicable) "
            name="stt"
            rules={[{ required: true, message: 'Student Year of School' }]}
          >
            <Input />
          </Form.Item>

         

  <Form.Item
    label="Do you give us permission to respond to your enquiry using your email and number? "
    name="permission"
    rules={[{ required: true, message: 'Please select Yes or No' }]}
  >
    <Select placeholder="Select Yes or No">
      <Option value="Yes">Yes</Option>
      <Option value="No">No</Option>
    </Select>
  </Form.Item>





          

          {/* Add other form fields based on your requirements */}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit"  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "10px 20px",
                    textDecoration: "none",
                    borderRadius: "5px",
                    height:"50px",
                    width:"100px"
                  }}>
             Submit
            </Button>
          </Form.Item>
        </Form>
      

      </Modal>

        <Footer />
      </React.Fragment>
    );
  }


export default ContactUs;
