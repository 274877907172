import React, { Component } from "react";
import Singleservice from "./SingleService";

// const services = [
//   {
//     title: "Free Weekly GCSE Science Class",
//     description:
//       "A weekly online class for a subject many students find difficult, run by experienced Medical Students. Completely on us.",
//     buttonLabel: "Sign Up",
//     actionLink: "/studentregister", // Replace with the actual link
//   },
//   {
//     title: "30-minute Free Trial",
//     description:
//       "Unlimited 30-minute free trial sessions so that your child can experience our tutoring first-hand and only proceed if they are entirely comfortable and satisfied with the tutor we have matched for them.",
//     buttonLabel: "Contact Us",
//     actionLink: "#contact", // Replace with the actual link
//   },
// ];

const titleStyle = {
  fontFamily: "Raleway, sans-serif",
 
  fontSize: "36px",

  color: "#000000",
  
};

class Services extends Component {
  render() {
    const sectionStyle = {
      backgroundColor: "#ebeadf",
      innerHeight:"400px"
    };

    const serviceItemStyle = {
      marginBottom: "20px", // Add space at the bottom of each service item
    };

    return (
      <React.Fragment>
        <section className="page-section" id="services" style={sectionStyle}>
          <div className="container">
            <div className="row justify-content-center">
              {/* {services.map((service, index) => { */}
              
                  {/* <div className="col-md-5" >
                     <div className="service-item text-center" style={serviceItemStyle}>
                    <h2 className=" " style={titleStyle}>
                    Free Weekly GCSE Science Class
                    </h2>
                    <p className="text-muted">A weekly online class for a subject many <br></br>students find difficult, run by experienced <br></br>Medical Students. Completely on us.
   </p>
                    <a href={"/contactus"} className="btn btn-dark btn-lg">
                    Sign Up
                    </a>
                  </div>
                  </div> */}
                  {/* <div className="col-md-2" ></div> */}
                  <div className="col-md-5" >
                  <div className="service-item text-center" style={serviceItemStyle}>
                    <h2  style={titleStyle}>
                    30 Minute Free Trial
                    </h2>
                    <p className="text-muted">Unlimited 30-minute free trial sessions so that your child can experience our tutoring first-hand and only proceed if they are entirely comfortable and satisfied with the tutor we have matched for them.
    </p>
                    <a href={"contactus"} className="btn btn-dark btn-lg">
                    Contact Us
                    </a>
                  </div>
</div>
                
                
            
           
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
