import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
// import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';

import {Formik,Form,Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {LoginSchema, RegisterSchema} from '../../../../utils/ValidationSchema'

// import bgImage from '../../assets/about.jpg'
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button'
import Breadcrumb from './Breadcrumb'
import axios from 'axios'
import Swal from 'sweetalert2'; // Import SweetAlert


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(3),
    //   padding:theme.spacing(2),
        // width: theme.spacing(3),
        width:'99%',
        height:'80ch',
        padding:theme.spacing(1),
        backgroundColor: theme.palette.grey[100],
    },
  },
  register: {
    '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '80%',
        marginLeft:'10%'
    },
  },
  paper:{
      width:'80%',
      marginTop:'2px',
      borderRadius:'15px',
    //   marginButtom:'2px'
  },
  profile:{
      width:'auto',
      justify:'center',
      marginLeft:'9%',
    //   marginButtom:'5px'
  }
}));

export default function SimplePaper() {
  const classes = useStyles();

  return (
      <React.Fragment>
          <Formik initialValues={{
              name:'',
              lastName:'',
              email:'',
              password:''
          }}

          onSubmit={async (data, { setSubmitting }) => {
            try {
              // Send registration data to the PHP endpoint
              const response = await axios.post('https://syllatutors.com/syllatutor/register_student.php', data);
        
              // Handle the response from the server
              console.log(response.data); // Server response
        if(response.data=="Student registration successful!"){
              // Display a success message using SweetAlert
              Swal.fire({
                icon: 'success',
                title: 'Registration Successful',
                text: 'You have successfully registered as a student!',
              });}
              else{
                Swal.fire({
                  icon: 'error',
                  title: 'already  registered',
                  text: 'You have already registered as a student!',
                });

              }
        
              // Optionally, you can perform client-side actions based on the response
              // For example, show a success message to the user
        
            } catch (error) {
              console.error('Error:', error);
        
              // Display an error message using SweetAlert
              Swal.fire({
                icon: 'error',
                title: 'Registration Failed',
                text: 'An error occurred while registering. Please try again.',
              });
        
              // Handle errors here, such as displaying an error message to the user
            } finally {
              setSubmitting(false); // Set submitting to false after form submission
            }
          }}
          
          validationSchema={RegisterSchema}
          >
              {({isSubmitting, submitForm})=>(
                <React.Fragment>
                <div className={classes.root}>
                    <Paper elevation={4}>
                        <Breadcrumb />
                        <Container maxWidth="md">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                <h1 className="profile_top pt-3">Register as Student </h1>
                                    <h1 className="profile"> Great decision.</h1>
                                    <h1 className="profile">Create your student profile!</h1>
                                      <div className="form_input">
                                        <Form className={classes.register} >
                                            <Field
                                            name="name"
                                            variant="outlined"
                                            type="text"
                                            label="Enter your name"
                                            component={TextField}
                                            />

                                            <Field
                                            name="lastName"
                                            variant="outlined"
                                            type="text"
                                            label="Enter your last name"
                                            component={TextField}
                                            />

                                            <Field
                                            name="email"
                                            variant="outlined"
                                            type="email"
                                            label="Enter your valid email"
                                            component={TextField}
                                            />

                                            <Field
                                            name="password"
                                            variant="outlined"
                                            type="password"
                                            label="Enter your password"
                                            component={TextField}
                                            />

                                            <div className={classes.profile}>
                                                <Button 
                                                startIcon={isSubmitting ? <CircularProgress size="1rem" /> :null}
                                                type="submit" 
                                                variant="contained" 
                                                color="primary" 
                                                className=" m-2" 
                                                disabled={isSubmitting}  
                                                onClick={submitForm}
                                            >
                                            Create a profile
                                            </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </Paper>

                            </Grid>
                        </Container>
                    </Paper>
                </div>
                </React.Fragment>
              )}
          </Formik>         
      </React.Fragment>
   
  );
}
