import React, { Component } from "react";
import bannerImage from '../assets/img/07/image-asset-1-1.jpeg'; // Make sure the path to your image is correct


const tabs = [
  "KS3",
  "GCSE",
  "A-Level",
  "Medical",
  "Interviews"
];

class Courseboard extends Component {
  render() {
    const bannerStyle = {
      backgroundImage: `url(${bannerImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };

    return (
      <React.Fragment>
        <section className="page-section banner-section" id="banner" style={bannerStyle}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="service-item text-center">
                  <h2 className="text-uppercase  text-white">Any Subject, Any Time</h2>
                  <p className="text-white"></p>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {tabs.map((tab, index) => {
                return (
                  <div className="col-md-2" key={index}>
                    <div className="service-item text-center">
                      <h2 className="text-uppercase  text-white">{tab}</h2>
                    </div>
                  </div>
                );
              })}
            </div>
            <br/>
            <div className="row justify-content-center">
              <div className="col-md-12">
               
                  <p className="text-white">Experience excellence in education with our expert tutoring services. Our highly qualified tutors provide personalized guidance and support, ensuring academic success and empowering students to reach their full potential. Invest in your education and unlock a world of knowledge.</p>
                
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Courseboard;
