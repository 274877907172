import React, { Component } from "react";
import Footer from '../../Common/Footer';

class SafeguardingPolicy extends Component {
  render() {
    const containerStyle = {
      backgroundColor: "#ebeadf",
        paddingTop: "120px",
      
     
      };
      const paragraphstyle = {
    
       textAlign:"justify",
       FontFace:"Georgia",    
     };
  
      const contentStyle = {
    
         backgroundColor: "#ebeadf",
         marginRight: "3%",
      
    
     
      };

    return (
      <React.Fragment>
        <section style={containerStyle}>
          <div className='container' style={contentStyle}>
            <h2>Safeguarding Policy</h2>
           <p style={paragraphstyle}>
              <strong>Last Updated:</strong> [23/06/2023]
            </p>

           <p style={paragraphstyle}>
              SyllaTutors is committed to creating a safe and secure environment for all users of our tutoring agency website (“Website”), especially children, young people, and vulnerable individuals. This safeguarding policy outlines the measures and procedures we have implemented to protect the welfare and well-being of our users. By accessing and using the Website, you agree to comply with this safeguarding policy.
            </p>

           <p style={paragraphstyle}>
              <strong>Child and Vulnerable Individual Protection</strong>
            </p>
           <p style={paragraphstyle}>
              (a) Duty of Care: SyllaTutors acknowledges its duty of care to safeguard and promote the welfare of children, young people, and vulnerable individuals who use our services.
            </p>
           <p style={paragraphstyle}>
              (b) User Safety: We strive to provide a safe online environment that is free from harm, abuse, harassment, or exploitation. We are committed to taking reasonable steps to prevent and address any safeguarding concerns.
            </p>
           <p style={paragraphstyle}>
              (c) Identification and Reporting: If any user encounters or suspects any form of abuse, inappropriate behavior, or safeguarding concern on the Website, it should be reported immediately to our designated safeguarding officer (contact details provided below).
            </p>

           <p style={paragraphstyle}>
              <strong>Safeguarding Measures</strong>
            </p>
           <p style={paragraphstyle}>
              (a) User Registration: We implement a user registration process, which includes age verification for individuals under the legal age of consent. We do not knowingly collect personal information from individuals below the legal age of consent without appropriate parental consent.
            </p>
           <p style={paragraphstyle}>
              (b) Age-Appropriate Content: We take reasonable measures to ensure that the content and materials provided on the Website are age-appropriate and suitable for the intended audience.
            </p>
           <p style={paragraphstyle}>
              (c) User Interaction: We monitor and moderate user interactions, including messages and comments, to identify and prevent any instances of abusive, harmful, or inappropriate behavior.
            </p>
           <p style={paragraphstyle}>
              (d) Reporting Mechanism: We provide a clear and accessible reporting mechanism for users to report any safeguarding concerns, including information about how to contact our designated safeguarding officer.
            </p>

           <p style={paragraphstyle}>
              <strong>Data Protection and Privacy</strong>
            </p>
           <p style={paragraphstyle}>
              (a) Data Security: We prioritize the security and confidentiality of personal information shared by our users. We follow best practices and implement technical and organizational measures to protect data from unauthorized access, loss, misuse, alteration, or disclosure.
            </p>
           <p style={paragraphstyle}>
              (b) Privacy Policy: We have a separate Privacy Policy that outlines how we collect, store, use, and disclose personal information. The Privacy Policy provides transparency regarding our data practices and users’ rights concerning their personal information.
            </p>

           <p style={paragraphstyle}>
              <strong>Staff Training and Awareness</strong>
            </p>
           <p style={paragraphstyle}>
              (a) Safeguarding Officer: SyllaTutors designates a safeguarding officer who is responsible for overseeing and coordinating all safeguarding efforts. The safeguarding officer can be contacted via the provided contact details.
            </p>
           <p style={paragraphstyle}>
              (b) Training: Our staff members and tutors undergo appropriate safeguarding training to ensure they are aware of their responsibilities, understand safeguarding procedures, and can respond effectively to any safeguarding concerns.
            </p>

           <p style={paragraphstyle}>
              <strong>Cooperation with Authorities</strong>
            </p>
           <p style={paragraphstyle}>
              We cooperate fully with law enforcement agencies, regulatory bodies, and child protection organizations in any investigation or inquiry related to safeguarding concerns on the Website.
            </p>

           <p style={paragraphstyle}>
              <strong>Contact Information</strong>
            </p>
           <p style={paragraphstyle}>
              If you have any safeguarding concerns, questions, or requests regarding this policy or any safeguarding matters related to SyllaTutors, please contact our designated safeguarding officer:
            </p>
           <p style={paragraphstyle}>
              [Name] [Position] [Email Address] [Phone Number]
            </p>
           <p style={paragraphstyle}>
              Please remember that in emergency situations or if you believe someone is at immediate risk of harm, you should contact your local emergency services or child protection helpline.
            </p>

           <p style={paragraphstyle}>
              We reserve the right to update or modify this safeguarding policy at any time without prior notice. The revised policy will be posted on our Website, and the “Last Updated” date will indicate the effective date of the revised policy.
            </p>

           <p style={paragraphstyle}>
              By accessing and using the SyllaTutors website, you agree to comply with this safeguarding policy and all applicable laws and regulations regarding the protection of children, young people, and vulnerable individuals.
            </p>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default SafeguardingPolicy;
