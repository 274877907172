import React from 'react';
// import './App.css';
import { Route, Switch } from 'react-router-dom';

import HomePage from './components/HomePage'          // website but was not implemented it was commented
import RegisterTutor from './components/RegisterTutor' // website but was not implemented it was commented
import Pagewrapper from './components/website/PageWrapper';
import Home from './components/website/Pages/Home';
import About from './components/website/Pages/About'; // website but was not implemented it was commented
import AdminWrapper from './components/website/AdminWrapper'; // website but was not implemented it was commented
import Loginregistration from './components/website/Registration/Login'; // website but was not implemented it was commented
import Login from './components/website/Pages/admin/Login'
import TrackTeacher from './components/website/Pages/admin/TrackTeacher'
import Register from './components/website/Pages/admin/Register'
import TeacherLogin from './components/website/Pages/Teacher/TeacherLogin'
import TeacherRegister from './components/website/Pages/Teacher/TeacherRegister'
import StudentLogin from './components/website/Pages/Student/StudentLogin'
import StudentRegister from './components/website/Pages/Student/StudentRegister'
import AppBar from './components/website/Pages/admin/AppBar'
import TestingReg from './components/website/Pages/admin/TestingReg'
import Adminpage from './components/website/Pages/admin/Dashboard'
import TeacherList from './components/website/Pages/admin/TeacherList'
import Teacherpage from './components/website/Pages/Teacher/Dashboard'
import Studentpage from './components/website/Pages/Student/Dashboard'
import Successpage from './components/website/Pages/SuccessPage'
import Cancelpage from './components/website/Pages/Cancel'
import Tos from './components/website/Pages/Misc/Tos'
import AboutUs from './components/website/Pages/Misc/AboutUs'
import Pricing from './components/website/Pages/Misc/Pricing'
import PrivacyPolicy from './components/website/Pages/Misc/PrivacyPolicy'
import CopyrightStatement from './components/website/Pages/Misc/CS'
import Safeguarding from './components/website/Pages/Misc/SPP'
import ContactUs from './components/website/Pages/Misc/ContactUs'
import TutorApplication from './components/website/Pages/Misc/TutorApplication'
import Popup from './components/website/Pages/Misc/popup'



function App() {
  return (

    
    <React.Fragment>

<Route   exact={true} path="/popup"  
      render={props=>(
        
          <Popup {...props}  />
      )}  />
       <Route   exact={true} path="/trackteacher"  
      render={props=>(
        
          <TrackTeacher {...props}  />
      )}  />
       <Route   exact={true} path="/teacherlist"  
      render={props=>(
        
          <TeacherList {...props}  />
      )}  />
      <Route   exact={true} path="/safeguarding"  
      render={props=>(
        <Pagewrapper>
          <Safeguarding  />
        </Pagewrapper>)}  />

        <Route   exact={true} path="/tutorapplication"  render={props=>(
        <Pagewrapper>
          <TutorApplication  />
        </Pagewrapper>)}  />

        <Route   exact={true} path="/contactus"  render={props=>(
        <Pagewrapper>
          <ContactUs  />
        </Pagewrapper>)}  />

<Route   exact={true}  path="/copyrigtstatement"  render={props=>(
        <Pagewrapper>
          <CopyrightStatement />
        </Pagewrapper>)}  />

<Route   exact={true}  path="/privacypolicy"  render={props=>(
        <Pagewrapper>
          <PrivacyPolicy {...props} />
        </Pagewrapper>)}  />

<Route   exact={true} path="/pricing"  render={props=>(
        <Pagewrapper>
          <Pricing {...props} />
        </Pagewrapper>)}  />



<Route   exact={true} path="/aboutus"  render={props=>(
        <Pagewrapper>
          <AboutUs  />
        </Pagewrapper>)}  />

<Route   exact={true} path="/termsservice"  render={props=>(
        <Pagewrapper>
          <Tos  />
        </Pagewrapper>)}  />
        <Switch>
        <Route path="/success" component={Successpage} />
        <Route path="/cancel" component={Cancelpage} />
        {/* Other routes */}
      </Switch>
        {/* <Route   exact={true} path="/success"  render={props=>(
        <Pagewrapper>
          <Successpage  />
        </Pagewrapper>)}  />

        <Route   exact={true} path="/cancel"  render={props=>(
        <Pagewrapper>
          <Cancelpage  />
        </Pagewrapper>)}  /> */}

{/* <Route path="/success" component={Successpage} /> */}

{/* <Route path="/cancel" component={Cancelpage} /> */}
<Route 
  exact={true} 
  path="/teacherdashboard" 
  render={props=>(
    <Teacherpage {...props} />
  )} 
/>

<Route 
  exact={true} 
  path="/studentdashboard" 
  render={props=>(
    <Studentpage {...props} />
  )} 
/>

<Route 
  exact={true} 
  path="/admin" 
  render={props=>(
    <Adminpage {...props} />
  )} 
/>
      
      
      <Route 
        exact={true} 
        path="/" 
        render={props=>(
        <Pagewrapper>
          <Home {...props} />
        </Pagewrapper>)} 
      />

      <Route 
        exact={true} 
        path="/login" 
        render={props=>(
        <AppBar>
          <Login {...props} />
        </AppBar>)} 
      />

      <Route 
        exact={true} 
        path="/register" 
        render={props=>(
        <AppBar>
          <Register {...props} />
        </AppBar>)} 
      />

<Route 
        exact={true} 
        path="/teacherlogin" 
        render={props=>(
        <AppBar>
          <TeacherLogin {...props} />
        </AppBar>)} 
      />

      <Route 
        exact={true} 
        path="/teacherregister" 
        render={props=>(
        <AppBar>
          <TeacherRegister {...props} />
        </AppBar>)} 
      />

<Route 
        exact={true} 
        path="/studentlogin" 
        render={props=>(
        <AppBar>
          <StudentLogin {...props} />
        </AppBar>)} 
      />

      <Route 
        exact={true} 
        path="/studentregister" 
        render={props=>(
        <AppBar>
          <StudentRegister {...props} />
        </AppBar>)} 
      />

      <Route 
        exact={true} 
        path="/reg" 
        render={props=>(
        <AppBar>
          <Home {...props} />
        </AppBar>)} 
      />
     
    </React.Fragment>
  );
}

export default App;
