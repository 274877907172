import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import BuySessionDialog from './BuySessionDialog'; // Import the custom dialog box component
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
// Define your styles here...
const dashboardStyles = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#f7f7f7',
  borderRadius: '5px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  marginTop: '20px',
};

const headerStyles = {
  backgroundColor: '#333',
  color: '#fff',
  textAlign: 'center',
  padding: '20px',
};

const tableStyles = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
  overflowX: 'auto',

};

const thStyles = {
  backgroundColor: '#333',
  color: '#fff',
  padding: '10px',
  textAlign: 'left',
};

const tdStyles = {
  border: '1px solid #ccc',
  padding: '10px',
};

const rowStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const circleStyles = {
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
};

const circleValueStyles = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '8px',
  color: '#333',
};

const logoutButtonStyles = {
  backgroundColor: '#333',
  color: '#fff',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '20px',
};
const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    border: 'none',
  },
};
const stripePromise = loadStripe('pk_live_51Nhe2QCM1Y3qdyF9WObKEiDZOzIMQBSInRaXBjqmoOJWuzxBm7UbHI0LHAa9ncdKlK8hnChgZJ9hAxlBIsKvdUWS00biICXxdv'); // Replace with your Stripe publishable key

function AdminDashboard() {
  const history = useHistory();
  const [data, setData] = useState({ teachers: [] });
  const [sessions, setSessions] = useState();
  const [remainingSessions, setRemainingSessions] = useState(0);
  const [consumedSessions, setConsumedSessions] = useState(0);
  const [isPasswordChangeModalOpen, setIsPasswordChangeModalOpen] = useState(false);
  // State to manage the custom dialog box
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const handleBuySession = () => {
    setIsDialogOpen(true); // Open the custom dialog box
  };

  const handleDialogSubmit = (quantity) => {
    console.log("Selected Quantity:", quantity);
    // Handle the quantity and submit to Stripe here
    // You can add your Stripe integration logic here
    setIsDialogOpen(false); // Close the dialog box
  };

  useEffect(() => {

    const studentIid = localStorage.getItem('studentId');
    const teacherId = localStorage.getItem('teacherId');
    const adminId = localStorage.getItem('Admin');
  
    if (studentIid) {
      history.push('/studentdashboard');
    } else if (teacherId) {
      history.push('/teacherdashboard');
    } else if (adminId) {
      history.push('/teacherlist');
    } else {
      history.push('/');
    }

    // Fetch students data
    const studentId = localStorage.getItem('studentId');
    axios
      .post('https://syllatutors.com/syllatutor/getalldetails.php', {
        studentId: studentId,
      })
      .then((response) => {
        setData((prevData) => ({ ...prevData, teachers: response.data.teachers }));
        setSessions(response.data.sessions.TotalSessions);
        console.log(response.data)

        // Calculate remaining sessions
        setConsumedSessions(response.data.sessions.TotalSessions)
        if(response.data.sessions.RemSessions==0){
          setRemainingSessions (totalSessions)
        }
        else {
        setRemainingSessions (response.data.sessions.RemSessions)}
      })
      .catch((error) => {
        console.error('Error fetching teachers:', error);
      });
  }, []);

  // Retrieve session data from localStorage
  const totalSessions = parseInt(localStorage.getItem('session')) || 0;

  const handlePasswordChange = () => {
    if (currentPassword && newPassword === confirmPassword) {
      const teacherId = localStorage.getItem('studentId');
      const passwordData = {
        id: teacherId,
        currentPassword,
        newPassword,
        role: 'Student'
      };
      
      console.log(passwordData);
      axios
        .post('https://syllatutors.com/syllatutor/passwordchange.php', passwordData)
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            setIsPasswordChangeModalOpen(false);
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            Swal.fire({
              icon: 'success',
              title: 'Password Changed Successfully',
              text: 'Your password has been updated!',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Password Change Failed',
              text: 'Please check your current password and try again.',
            });
          }
        })
        .catch((error) => {
          console.error('Error changing password:', error);
        });
    } else {
      console.error('Invalid password change request');
    }
  };
  const handleLogout = () => {
    localStorage.removeItem('studentId')
    localStorage.removeItem('quantity');
    window.location.href = '/'; // Redirect to the login page
  };

  return (
    <div style={dashboardStyles}>
  <div></div>
        <div style={headerStyles}>
          <h1>Welcome to the Student Dashboard</h1>
          <Button variant="contained" color="secondary"  onClick={handleLogout}>
            Logout
          </Button>
          <Button variant="contained" color="primary" onClick={() => setIsPasswordChangeModalOpen(true)}>
          Change Password
        </Button >
          <Elements stripe={stripePromise} > 
            <BuySessionButton onClick={handleBuySession} />
          </Elements>
        </div>
        <h2>Session Details</h2>

        <div style={rowStyles}>
          <div>
            <p>Total Sessions</p>
            <center>
              <div style={circleStyles}>
                <p style={circleValueStyles}>{totalSessions}</p>
              </div>
            </center>
          </div>
          <div>
            <p>Consumed Sessions</p>
            <center>
              <div style={circleStyles}>
                <p style={circleValueStyles}>{consumedSessions}</p>
              </div>
            </center>
          </div>
          <div>
            <p>Remaining Sessions</p>
            <center>
              <div style={circleStyles}>
                <p style={circleValueStyles}>{remainingSessions}</p>
              </div>
            </center>
          </div>
        </div>

        <h3>Teacher Engaged</h3>
        <div style={tableStyles}>
        <table >
          <thead>
            <tr>
              <th style={thStyles}>ID</th>
              <th style={thStyles}>Firstname</th>
              <th style={thStyles}>Lastname</th>
              <th style={thStyles}>Email</th>
            </tr>
          </thead>
          <tbody>
            {data.teachers.map((teacher) => (
              <tr key={teacher.Id}>
                <td style={tdStyles}>{teacher.Id}</td>
                <td style={tdStyles}>{teacher.Teacher_First_Name}</td>
                <td style={tdStyles}>{teacher.Teacher_Last_Name}</td>
                <td style={tdStyles}>{teacher.Email}</td>
              </tr>
            ))}
          </tbody>
        </table>
     </div>
      <div>
        {/* Custom dialog box */}
        <BuySessionDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSubmit={handleDialogSubmit}
        />
         {/* Password Change Modal */}
      <Modal
        isOpen={isPasswordChangeModalOpen}
        onRequestClose={() => setIsPasswordChangeModalOpen(false)}
        style={customModalStyles}
      >
        <h2>Change Password</h2>
        <div>
          <label>Current Password:</label>
          <input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
        </div>
        <div>
          <label>New Password:</label>
          <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </div>
        <div>
          <label>Confirm Password:</label>
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
        <Button variant="contained" color="primary" onClick={handlePasswordChange}>
          Save
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setIsPasswordChangeModalOpen(false)}>
          Cancel
        </Button>
      </Modal>
      </div>
    </div>
  );
}

function BuySessionButton({ onClick }) {
  return <Button onClick={onClick} variant="contained" color="secondary" >Buy Session</Button>;
}

export default AdminDashboard;
